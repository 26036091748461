import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Container from '../components/container'
import { Link, useI18next } from 'gatsby-plugin-react-i18next'

function ErrorPage({ location }) {
  return (
    <Layout location={location}>
      <Container>
        <section class="px-4 py-32 mx-auto max-w-7xl">
          <div class="w-full mx-auto lg:w-1/3">
            <Link to={'/'} title="Home Page" class="flex items-center">
              <span class="sr-only">OnMyGrad Home Page</span>
            </Link>
            <p class="mt-5 mb-3 text-xl font-bold text-black md:text-2xl">
              Page not found (404)
            </p>
            <p class="mb-3 text-base font-medium text-gray-700">
              The page you're looking for may have moved or no longer exists.
              Head back to our {``}
              <Link
                to={'/'}
                title="Home Page"
                className="underline hover:text-brand"
              >
                homepage
              </Link>
              , or take a look at one of the sections below.
            </p>
          </div>
        </section>
      </Container>
    </Layout>
  )
}

export default ErrorPage

export const pageQuery = graphql`
  query ErrorPageQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
