import React from 'react'

const Container = ({ children, as = 'div' }) => {
  const Tag = as

  return (
    <Tag className="container px-8 m-auto md:px-12 lg:px-20  md:mb-20 pt-16 md:pt-24">
      {children}
    </Tag>
  )
}

export default Container
